"use client";

import { authClient } from "@/lib/auth-client";
import { AlertCircle, Loader2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useMemo, useState, useTransition } from "react";
import { toast } from "sonner";
import isEmail from "validator/es/lib/isEmail";
import { Alert, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../ui/input-otp";
import { Label } from "../ui/label";

export default function EmailSignInForm({
  t,
  lng = "en",
}: {
  t: any;
  lng?: string;
}) {
  const [emailOTPSent, setEmailOTPSent] = useState(false);
  const [emailOTP, setEmailOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const router = useRouter();

  const [isPending, startTransition] = useTransition();

  const validateEmail = (email: string) => {
    return isEmail(email);
  };

  const sendEmailOTP = async () => {
    if (!validateEmail(email)) {
      setEmailError(t("email.invalid"));
      return;
    }
    setEmailError("");
    try {
      setLoading(true);
      const res = await authClient.emailOtp.sendVerificationOtp(
        {
          email: email,
          type: "sign-in", // or "email-verification", "forget-password"
        },
        {
          headers: {
            "Accept-Language": lng,
          },
        },
      );
      if (res.data?.success) {
        setEmailOTPSent(true);
        setResendCooldown(60);
        const timer = setInterval(() => {
          setResendCooldown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    } catch (error: any) {
      toast.error(error.message || "Please try again later");
    } finally {
      setLoading(false);
    }
  };

  const verifyEmailOTP = async () => {
    setLoading(true);
    try {
      const res = await authClient.signIn.emailOtp({
        email: email,
        otp: emailOTP,
      });
      if (res.error) {
        toast.error(res.error.message);
        setLoading(false);
        return;
      }
      if (res.data.token) {
        startTransition(() => {
          router.push("/home");
        });
      }
    } catch (error: any) {
      toast.error(error.message || "Please try again later");
    } finally {
      setLoading(false);
    }
  };

  const disableResendButton = useMemo(() => {
    if (loading) return true;
    return resendCooldown > 0;
  }, [resendCooldown, loading]);

  return (
    <div className="relative flex flex-col gap-4">
      {isPending && (
        <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-white/50">
          <Loader2 className="animate-spin" size={24} />
        </div>
      )}

      <div className="flex flex-col gap-2">
        <Label>{t("email")}</Label>
        <Input
          defaultValue={email}
          type="email"
          name="email"
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError("");
          }}
          placeholder={t("email.placeholder")}
        />
        {emailError && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>{emailError}</AlertTitle>
          </Alert>
        )}
      </div>

      {emailOTPSent && (
        <div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="code">{t("verification.code")}</Label>
            <InputOTP maxLength={6} onChange={(value) => setEmailOTP(value)}>
              <InputOTPGroup>
                {Array.from({ length: 6 }).map((_, index) => (
                  <InputOTPSlot key={index} index={index} />
                ))}
              </InputOTPGroup>
            </InputOTP>
          </div>

          <div className="flex flex-wrap items-center gap-2 text-sm text-gray-500">
            {t("verification.code.tip")}
            <Button
              variant={"link"}
              className="p-0"
              onClick={() => {
                sendEmailOTP();
              }}
              disabled={disableResendButton}
            >
              {loading ? <Loader2 className="animate-spin" size={14} /> : null}
              {resendCooldown > 0
                ? t("verification.code.resend.cooldown", {
                    count: resendCooldown,
                  })
                : t("verification.code.resend")}
            </Button>
          </div>
        </div>
      )}

      <Button
        className="w-full"
        type="button"
        onClick={() => {
          if (emailOTPSent && emailOTP) {
            verifyEmailOTP();
            return;
          }
          if (!email) {
            toast.error(t("verification.code.error.email"));
            return;
          }
          if (emailOTPSent) {
            toast.error(t("verification.code.error"));
            return;
          }
          sendEmailOTP();
        }}
        disabled={loading}
      >
        {loading ? <Loader2 className="animate-spin" /> : null}
        {t("continueWithEmail")}
      </Button>
    </div>
  );
}
